<template>
	<!-- Wrap your dialog in a `TransitionRoot`. -->
	<TransitionRoot
		:show="isOpen"
		as="template"
	>
		<Dialog @close="handleOutsideClick">
			<!-- Wrap your backdrop in a `TransitionChild`. -->
			<TransitionChild
				as="template"
				enter="ease-in-out duration-200"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="ease-in-out duration-200"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div class="fixed inset-0 z-30 transition-opacity bg-black/60" />
			</TransitionChild>
			<!-- Full-screen container to center the panel -->
			<div class="fixed inset-0 z-40 flex items-center justify-center w-screen max-h-screen px-4 overflow-y-auto lg:px-0">
				<!-- Wrap your panel in a `TransitionChild`. -->
				<TransitionChild
					as="div"
					enter="duration-300 ease-out"
					enter-from="opacity-0 scale-95"
					enter-to="opacity-100 scale-100"
					leave="duration-200 ease-in"
					leave-from="opacity-100 scale-100"
					leave-to="opacity-0 scale-95"
					class="w-full"
				>
					<!-- The actual dialog panel -->
					<DialogPanel
						:class="[
							'relative mx-auto max-h-screen bg-white rounded-xl shadow-xl isolate overflow-hidden dark:bg-mx-green-700',
							maxWidth,
							{ 'px-2 py-6': !hidePadding }
						]"
					>
						<!--
						<div
							class="absolute inset-x-0 top-[-10rem] -z-10 overflow-hidden blur-3xl sm:top-[-20rem]"
							aria-hidden="true"
						>
							<div
								class="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr to-[#ff5733]/40 from-[#900c3f] via-[#c70039] opacity-20 dark:opacity-20"
								style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"
							/>
						</div>
						<div
							class="absolute left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl top-0"
							aria-hidden="true"
						>
							<div
								class="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#900c3f] to-[#ff5733]/40 opacity-20 dark:opacity-20"
								style="clip-path: polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)"
							/>
						</div>
						-->
						<!-- 'x' close button -->
						<button
							v-if="!hideClose"
							type="button"
							class="absolute top-0 right-0 p-2 m-0.5 text-mx-gray-400 rounded-full hover:text-mx-gray-500 hover:bg-mx-gray-100 dark:hover:bg-mx-green-600 dark:hover:text-mx-gray-300 focus:outline-none"
							@click="closeModal"
						>
							<span class="sr-only">
								Close
							</span>
							<svg
								class="w-3 h-3"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
						<div class="max-h-[90vh]">
							<!-- default slot for the dialog panel -->
							<slot name="default">
								<DialogTitle
									v-if="!hideTitle"
									:class="titleClasses"
								>
									<slot name="title">
										<span class="text-3xl bg-clip-text bg-text-gradient">
											{{ title }}
										</span>
									</slot>
								</DialogTitle>
								<div
									:class="['max-h-[70vh] overflow-x-hidden overflow-y-auto',
										{ 'px-4': !hidePadding }
									]
									"
								>
									<!-- body slot for the dialog panel -->
									<slot
										name="body"
										:close-modal="closeModal"
									>
										<div class="flex flex-col gap-4">
											<div :class="descriptionClasses">
												{{ description }}
											</div>
										</div>
									</slot>
								</div>
								<!-- footer slot for the dialog panel -->
								<slot
									v-if="!hideFooter"
									name="footer"
								>
									<div class="flex flex-row justify-end gap-4 px-4 pt-4">
										<button
											v-if="!hideCancel"
											type="button"
											:class="cancelClasses"
											@click="emitCancel"
										>
											{{ cancelText }}
										</button>
										<FormButton
											v-if="!hideConfirm"
											type="button"
											:button-width="confirmWidth"
											:class="confirmClasses"
											submit
											:disabled="confirmDisabled"
											@submit="emitConfirm"
										>
											{{ confirmText }}
										</FormButton>
									</div>
								</slot>
							</slot>
						</div>
					</DialogPanel>
				</Transitionchild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script setup>
import {
	TransitionRoot,
	TransitionChild,
	Dialog,
	DialogPanel,
	DialogTitle
} from '@headlessui/vue'

const props = defineProps({
	id: {
		type: String,
		required: true
	},
	title: {
		type: String,
		default: 'Modal Title'
	},
	description: {
		type: String,
		default: ''
	},
	confirmText: {
		type: String,
		default: 'Confirm'
	},
	cancelText: {
		type: String,
		default: 'Cancel'
	},
	overrideControls: {
		type: Boolean,
		default: false
	},
	isOpen: {
		type: Boolean,
		default: true
	},
	titleClasses: {
		type: String,
		default: 'text-lg font-bold px-4'
	},
	descriptionClasses: {
		type: String,
		default: 'text-sm text-mx-gray-500'
	},
	confirmClasses: {
		type: String,
		default: 'inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-white bg-mx-orange border border-transparent rounded-md shadow-sm hover:bg-mx-orange/70 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mx-orange'
	},
	cancelClasses: {
		type: String,
		default: 'dark:bg-mx-green-900 dark:text-mx-gray-50 dark:border-mx-green-700 dark:hover:bg-mx-green-800 inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-mx-green-700 bg-white border border-mx-gray-300 rounded-md shadow-sm hover:bg-mx-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mx-orange w-24'
	},
	confirmWidth: {
		type: String,
		default: 'w-24'
	},
	hideFooter: {
		type: Boolean,
		default: false
	},
	hideTitle: {
		type: Boolean,
		default: false
	},
	hideCancel: {
		type: Boolean,
		default: false
	},
	hideConfirm: {
		type: Boolean,
		default: false
	},
	hideClose: {
		type: Boolean,
		default: false
	},
	maxWidth: {
		type: String,
		default: 'max-w-lg'
	},
	confirmDisabled: {
		type: Boolean,
		default: false
	},
	preventOutsideClickClose: {
		type: Boolean,
		default: false
	},
	hidePadding: {
		type: Boolean,
		default: false
	}
})

const { overrideControls, confirmDisabled, preventOutsideClickClose } = toRefs(props)

const emit = defineEmits([
	'onConfirm',
	'onCancel',
	'onClose'
])

const handleOutsideClick = () => {
	if (!preventOutsideClickClose.value) {
		closeModal()
	}
}

const emitConfirm = () => {
	if (!confirmDisabled.value) {
		emit('onConfirm')
		if (!overrideControls.value) {
			closeModal()
		}
	}
}

const emitCancel = () => {
	emit('onCancel')
	if (!overrideControls.value) {
		closeModal()
	}
}

const closeModal = () => {
	emit('onClose')
}
</script>
