import { ref } from 'vue'

const activeModalId = ref<string | null>(null)

export function useModal () {
	const openModal = (modalId: string) => {
		activeModalId.value = modalId
	}

	const closeModal = () => {
		activeModalId.value = null
	}

	const isModalOpen = (modalId: string) => {
		return activeModalId.value === modalId
	}

	return {
		activeModalId,
		openModal,
		closeModal,
		isModalOpen
	}
}
